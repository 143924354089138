import styled from "styled-components";
//Images
import Aurora from "../images/AuroraImage1.jpeg"

export const Card = styled.div`
  /* background-color:${({theme}) => theme.color} ; */
  width:400px;
  height:400px;
  box-shadow:5px 5px 10px 2px #333333;
  /* border-radius:20px; */
`;

export const CardMedium = styled(Card)`
  opacity:0;
  animation:fade-in 2s forwards;
  animation-delay:3s;
  margin:10% auto;
  @media screen and (max-width: 1117px) {
    width:300px;
    height:300px;
  }
  @media screen and (max-width: 455px) {
    width:250px;
    height:250px;
  }
  h3{
    padding:10px;
    background-color:black;
    color: rgb(184, 135, 156);
    font-weight:400;
  }
  /* border-radius:20px; */
`;

export const CardBig = styled(Card)`
  background-image: url(${Aurora});
  background-size:contain;
  background-repeat:no-repeat;
  background-position:right;
  width:650px;
  height:650px;
  margin:5% auto;
  position:relative;
  @media screen and (max-width: 1051px) {
    width:500px;
    height:500px;
  }
  @media screen and (max-width: 579px) {
    width:300px;
    height:300px;
  }

  

  .black-half{
    background-color:black;
    width:50%;
    height:inherit;
    height:inherit;
    position:absolute;
    top:0;
    z-index:1;
    overflow:hidden;
    
    .introduction{
      color:white;
      font-weight:300;
      font-size:20px;
      margin:50% 40%;
      animation: fade-in 2s ease-in-out forwards,
      move-back 2s ease-in-out forwards 8s;
      opacity:0;
      animation-delay: 4s;
      margin:0% 40%;
      @media screen and (max-width: 579px) {
      font-size:13px;
      }
      span{
        color:#b8879c;
      }
    }

    .code-lines{
      display:flex;
      p{
      margin:35px;
      color:white;
      font-size:20px;
      animation: scroll-up 5s linear forwards;
      writing-mode: vertical-rl;
      text-orientation: upright;
      line-height:100%;
      }
      p:nth-child(2) {
      animation: scroll-down 5s linear forwards;
    }
    }

/* Animations */

    @keyframes scroll-up {
    from {
      transform: translateY(0%);
      visibility: visible;
    }
    to {
      transform: translateY(-100%);
      opacity:0;
      visibility:hidden;
    }
    }

    @keyframes scroll-down {
    from {
      transform: translateY(0%);
      visibility: visible;
    }
    to {
      transform: translateY(100%);
      opacity:0;
      visibility:hidden;
    }
    }

    @keyframes fade-in {
    from {
        opacity:0;
    }
    to {
        visibility:visible;
        opacity:1;
    }
    }

    @keyframes fade-out {
    from {
        opacity:1;
    }
    to {
        opacity:0;
    }
    }

    @keyframes move-back {
    from {
      margin-top: 0%;
    }
    to {
      margin-top: 50%;
    }
  }
  @keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(50deg) ;
  }
}
  }

  .black-half.left{
    left:0;
    border-top-left-radius:inherit;
    border-bottom-left-radius:inherit;
  }

  .black-half.right{
    right:0;
    border-top-right-radius:inherit;
    border-bottom-right-radius:inherit;
    animation: fade-out 5s ease-in-out forwards;
    animation-delay: 4s;
    opacity:1;
  }
`;
