import {createGlobalStyle} from 'styled-components';

export const Base = {
color: "white",
};
export const Dark = {
color: "black",
}

export const GlobalStyles = createGlobalStyle`
html{
  scroll-behavior: smooth;
}
body{
  background: linear-gradient(to right, rgb(176, 176, 200), pink);
  cursor:copy;
}
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Source Code Pro', monospace;
}
`