import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  margin: auto;
`;

export const FlexedContainer = styled(Container)`
display:flex;
`;

export const Grid= styled.div`
width: 50%;
padding:100px;
@media screen and (max-width: 1117px) {
    padding:50px;
    width:100%
  }
`;