import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareGithub } from "@fortawesome/free-brands-svg-icons";


export const Title = styled.div`
text-align:center;
padding:100px 0;
@media screen and (max-width: 1117px) {
    padding:100px 20px;
  }
h2{
  color:black;
  margin-bottom:10px;
}
h3{
  font-weight:normal;
}
.github-icon{
font-size:50px;
cursor:help;
color:black;
}
`; 

export const ScrollingWrapper= styled.div`
  overflow-x: scroll;
  overflow-y: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE */
  ::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari */
  }
  padding-bottom:100px;
`;

export const ProjectCard = styled.a`
display: inline-block;
width:500px;
height:500px;
background-color:black;
margin:0 20px;
padding:10px;
position:relative;
box-shadow:rgb(51, 51, 51) 5px 5px 10px 2px;
@media screen and (max-width: 1051px) {
    width:400px;
    height:400px;
  }
  @media screen and (max-width: 579px) {
    width:250px;
    height:250px;
  }
h2{
    z-index:1;
    position:absolute;
    top:0;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    color:white;
    background-color:black;
    padding:10px;
    word-wrap: break-word;
    @media screen and (max-width: 1051px) {
    font-size:20px;
  }
  @media screen and (max-width: 579px) {
    font-size:12px;
  }
  }
  
div{
  filter: grayscale(100%);
  height:100%;
  width:100%;
  background-repeat: no-repeat;
  background-size: cover;
  &:hover{
    filter:blur(3px)
  }
}
.fridge{
  background-image: url("/fridge.jpg");
}
.ties{
  background-image: url("/ties.png");
}
.v-office{
  background-image: url("/v-office.png");
}
.porftolio-davide{
  background-image: url("/portfolio-davide.png");
}
.netflix{
  background-image: url("/netflix.jpeg");
}
`;  

export function Projects() {
  const scrollingWrapperRef = useRef();
  useEffect(() => {
    const wrapper = scrollingWrapperRef.current;
    if (wrapper) {
      const onWheel = e => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        wrapper.scrollTo({
          left: wrapper.scrollLeft + e.deltaY,
          behavior: "smooth"
        });
      };
      wrapper.addEventListener("wheel", onWheel);
      return () => wrapper.removeEventListener("wheel", onWheel);
    }
  }, []);

    return (
      <div  id="projects">
        <Title>
          <h2>Projects</h2>
          <div>
            <h3>Find code and other projects on my Github <a href="https://github.com/Rorins">
              <FontAwesomeIcon
                className="github-icon"
                icon={faSquareGithub}
              />
            </a></h3>
          </div> 
        </Title>
       <ScrollingWrapper ref={scrollingWrapperRef}>
        <ProjectCard href="https://v-office.vercel.app/">
        <h2>Virtual Office</h2>
        <div className="v-office">
        </div>
        </ProjectCard>

        <ProjectCard href="https://ties-official.vercel.app/">
        <h2>Ties mental health</h2>
        <div className="ties"></div>
        </ProjectCard>

        <ProjectCard href="https://fridgy-fridge.vercel.app">
        <h2>Fridgy Fridge</h2>
        <div className="fridge"></div>
        </ProjectCard>

        <ProjectCard>
        <h2>Netflix clone</h2>
        <div className="netflix"></div>
        </ProjectCard>

        <ProjectCard href="https://www.davidesantonocito.com/">
        <h2>Collaboration in portoflio</h2>
        <div className="porftolio-davide"></div>
        </ProjectCard>
       </ScrollingWrapper>
       </div>
    );
  }
