import styled from "styled-components";

export const About = styled.div`
  /* background-color:${({ theme }) => theme.color} ; */
  text-align: center;
  background-color:black;
  animation:4s openUp;
  display:flex;
  @media screen and (max-width: 1117px) {
    display:block;
  }

  h2,ul,p{
    opacity:0;
    animation:4s opacity forwards;
    animation-delay:3s;
  }
  h2{
    color:white;
    font-weight:400;
    margin:40px 0px;
  }
  p{
    color:white;
    font-weight:300;
    line-height:50px;
  }
  ul{
    list-style:none;
    li{
    color:white;
    font-weight:300;
    line-height:50px;
    span{
      font-weight:500;
      color:#b8879c;
    }
  }
  }

  @keyframes openUp{
      0%{
        transform:scaleX(0);
      }
      100%{
        transform:scaleX(1);

      }
    }
    @keyframes opacity{
      0%{
        opacity:0;
      }
      100%{
        opacity:1;
      }
    }
`;  