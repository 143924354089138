import React from 'react';
import styled from 'styled-components';

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default function VideoPreview({ videoUrl }) {
    return (
      <Video autoPlay muted loop playsInline>
        <source src={videoUrl} type="video/mp4" />
      </Video>
    );
  }


