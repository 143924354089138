import React, { useState, useEffect, useRef} from 'react';
//Animation
import AOS from 'aos';
import 'aos/dist/aos.css';
import VideoPreview from './components/VideoPreview';
//Assets and videos
import coding from "./components/videos/coding.mp4";
//Styling
import {ThemeProvider} from 'styled-components';
import {Base, Dark ,GlobalStyles} from "./themes/theme.js";
//Sections and components
import Loading from './components/Loading.js'
import { Container , FlexedContainer , Grid } from "./components/Containers.styled";
import { Card, CardImage, CardCode, CardBig , CardMedium} from "./components/Cards.styled";
import { Hero ,Nav, Title} from "./components/Hero.styled";
import { About } from "./components/About.styled";
import { Projects } from "./components/Projects.styled";

function App() {
//Theme set
const [theme, setTheme] = useState("light");
const isDarkTheme = theme === "dark";

const toggleTheme = () =>{
  setTheme(isDarkTheme ? "dark" : "light");
}

// Animation
useEffect(() => {
  AOS.init();
}, []);

//Focus
const focus = useRef(null);
useEffect(() => {
  focus.current.focus();
}, []);

//Loading
const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <ThemeProvider theme={Base}>
    <GlobalStyles />

    {loading ?
    (<Loading>
    </Loading> ) :
    
    (
    <>
    {/* Hero */}
    <Hero ref={focus}>

     

     <CardBig data-aos="flip-right" data-aos-duration="3000">
        <div className="black-half left">
          <p className="introduction">
            Hello, My name is <span>Aurora </span>
            and
            Coding is my passion.
          </p>
        
        <div className="code-lines">
         <p>
          0101010101
        </p>
        <p>
          0101010101
        </p>
        <p>
          0101010101
        </p>
        
        </div>
        
        </div>

        <div className="black-half right">
        
        <div className="code-lines">
         <p>
          0101010101
        </p>
        <p>
          0101010101
        </p>
        <p>
          0101010101
        </p>
        </div>
        </div>

        <Title>
        <h1>
        Web
        </h1>
        <span className="colored">Developer</span>
       </Title>

       <Nav>
      </Nav>
       </CardBig>
    
    </Hero >

    {/* About */}
    <About id="about">
    <Grid>
    <h2>About</h2>
    <ul>
      <li>const <span>name</span> = My name is Aurora Grippaudo.</li>
      <li>let <span>location</span> = Located in Europe and Usa.</li>
      <li>let <span>description</span> = Coding Bootcamp grad and tech enthusiast,
          studied as a full-stack developer and worked as a front-end developer.</li>
      <h2>Skills</h2>
      <li>let <span>hard skills</span> =<span>{`{`}</span></li>
      <li><span>frontend</span>:[ Javascript, html, css, sass, bootstrap, React, Vue, Gatsby, Next, Nuxt, CMS Headless Storyblok.],</li>
      <li><span>backend</span>:[ php, relational and non-relational database, SQL, Laravel, Firebase.]<span>{`}`}</span></li>
      <li>let <span>soft skills</span> = [</li>
      <li>empathy, creativity, motivation.]</li>
      </ul>
      </Grid>

      <Grid>
      <h2>Who am I?</h2>
      <p>Gaming, art and tech makes me the nerd I am.</p><p>But traveling, cooking and special moments shared with loved ones are all things I enjoy as well.</p>
     

      <CardMedium>
      <h3>Coding</h3>
      <VideoPreview videoUrl={coding} />
      </CardMedium>

      </Grid>
    </About>
    </>)}
   
   {/*Projects*/}
    <Projects>
    </Projects>

    </ThemeProvider>
  );
}

export default App;
