import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

export const Hero = styled.div`
  text-align: center;
  @media screen and (max-width: 1297px) {
    margin-top:200px;
  }
  @media screen and (max-width: 1051px) {
    margin-bottom:160px;
  }
  @media screen and (max-width: 579px) {
    margin-top:130px;
  }
`;  

export const List= styled.ul`
display:flex;
list-style:none;
margin-left:100px;
position:absolute;
top:200px;
left:-300px;
flex-direction:column;
//Mediaquery
@media screen and (max-width: 1051px) {
    margin:0px;
    top:530px;
    left:50px;
    flex-direction:row;
    height:70px;
  }
  @media screen and (max-width: 579px) {
    top:350px;
    left:0px;
  }
a{
  text-decoration:none;
}
  li{
    background-color:black;
    margin:0 10px;
    padding:10px;
    box-shadow: 5px 5px 10px 2px #333333;
    cursor:help;
    margin-bottom:30px;
    color:white;
    &:hover{
      color:black;
      background-color:white;
      a{
        color:black;
      }
    }
    a{
    color:white;
    font-weight:400;
    text-decoration:none;
    cursor:help;
    }
  }
  
`;


export const Title = styled.div`
position:absolute;
left:560px;
top:-60px;
z-index:1;

h1,.colored{
font-size:100px;
font-weight:normal;
}

//Mediaquery
@media screen and (max-width: 1611px) {
    h1,.colored {
      font-size: 80px;
    }
  }

  @media screen and (max-width: 1391px) {
    h1,.colored {
      font-size: 70px;
    }
  }

  @media screen and (max-width: 1297px) {
   left:150px;
   top:-200px;
  }

@media screen and (max-width: 1051px) {
   left:50px;
  }

  @media screen and (max-width: 579px) {
    top:-120px;
    h1,.colored {
      font-size:40px;
    }
  }




h1{
  visibility:hidden;
  opacity:0;
  animation:appear 3s ease-in-out forwards;
  animation-delay:4s;
}

  .colored{
    background-color:black;
    color:white;
    display: block;
    opacity:0;
    width: 0;
    overflow: hidden;
    animation:open 3s ease-in-out forwards;
    animation-delay:4s;
    box-shadow: 5px 5px 10px 2px #333333;

    //Animations
    @keyframes open{
      0%{
        width: 0;
        opacity:0;
      }

      100%{
        width: 100%;
        opacity:1;
      }
    }

    @keyframes appear{
      0%{
        opacity:0
      }

      100%{
        visibility:visible;
        opacity:1;
      }
    }
  }
`;  

export function Nav() {
  return (
      <List data-aos="flip-right" data-aos-duration="3000">
        <a href="#about">
          <li>
          About
          </li>
        </a>
        
        <a href="#projects">
          <li>
          Projects
          </li>
          </a>
        
        <a href="https://www.linkedin.com/in/auroragrippaudo/">
        <li className="linkedin">
        Contact 
        </li>
        </a>
      </List>
  );
}
