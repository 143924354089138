import React from 'react';
import styled from 'styled-components';

export const Animation= styled.div`
   display:flex;
   justify-content:center;
   align-items:center;
   width: 100%;
   height: 100vh;
   position: fixed;
   background:black;
   z-index: 90;

    .square{
        width:150px;
        height:150px;
        border:2px solid white;
        animation: transform 3s infinite;
        @media screen and (max-width: 579px) {
            width:100px;
            height:100px;
        }
    }

    @keyframes transform{
  0% {transform:rotate(0deg);}
  100% {transform:rotate(320deg);}
}
`;

export default function Loading() {
    return (
        <Animation>
            <div className="square">
            </div>
            <div className="square">
            </div>
            <div className="square">
            </div>
        </Animation>
    );
  }


